import _ from "lodash";

export const orderListCommons = function(ctrl, afterFill, LocalNetworkProfile, LocalUserPermissions) {
  if (afterFill) {
    return;
  }
  ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
  ctrl.orderFields = ['name', 'executionState', {param: 'externalReference', label: 'extReference'}, {param: 'Advertiser', label: 'advertiser'}, {param: 'Team', label: 'team'}, {param: 'Salesperson', label: 'salesperson'}, {param: 'AdOps', label: 'adOps'}, 'createUser', 'updateTime'];
  if (ctrl.hasObjectSource) {
    ctrl.orderFields.splice(1, 0, 'source');
  }
  if (!LocalUserPermissions.getAllVisPermissions().MANAGE_ADVERTISING) {
    ctrl.orderFields = ['name', 'executionState', {param: 'externalReference', label: 'extReference'}, {param: 'Advertiser', label: 'advertiser'}, {param: 'Salesperson', label: 'salesperson'}, {param: 'AdOps', label: 'adOps'}, 'createUser', 'updateTime'];
  }

  ctrl.orderFields.push({param: "spendDelivery", numeric: true});
  ctrl.orderFields.push({labelInstant: LocalNetworkProfile.getNetworkTranslations('impression'), param: "impressionDelivery", numeric: true});
  ctrl.orderFields.push({labelInstant: LocalNetworkProfile.getNetworkTranslations('rendered'), param: "renderedDelivery", numeric: true});
  ctrl.orderFields.push({param: "viewableDelivery", numeric: true});
  ctrl.orderFields.push({param: "clickDelivery", numeric: true});
  ctrl.orderFields.push({param: "viewability", numeric: true});
  ctrl.orderFields.push({param: "ctr", numeric: true});
  ctrl.orderFields.push({param: "deliveryPercentage", numeric: true});
  ctrl.orderFields.push({param: "budgetSpendPercentage", numeric: true});

  const skipForDefaults = ['source', 'createUser', 'spendDelivery', 'impressionDelivery', 'renderedDelivery', 'clickDelivery', 'viewableDelivery', 'viewability', 'ctr', 'deliveryPercentage', 'budgetSpendPercentage'];
  ctrl.defaultFields = _.filter(ctrl.orderFields, function(f) {
    return skipForDefaults.indexOf(f.label || f.param || f) < 0;
  });
  ctrl.sel = {};
  ctrl.perms = LocalUserPermissions.getAllVisPermissions();

  ctrl.applyLimitedFilter = function() {
    ctrl.limitedFilter = true;
  };
};
