import angular from 'angular';

import editTemplate from './order-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './order-list.html';

import standardModule from '../common/standard-module';
import orderController from './order-controller';
import orderInformationCtrl from './order-information';
import orderListComponent from '../common/controller/order-list-component';
import orderActionsController from './order-actions-controller';
import objectiveViewComponent from '../line-item/widget/objectives-view-component';
import bidsViewComponent from '../line-item/widget/bids-view-component';
import informationTemplate from "./order-information.html";
import singlePanelBottomTemplate from "../common/single-panel-bottom.html";
import {orderListCommons} from "./order-commons";

const MODULE_NAME = 'order-module';

angular.module(MODULE_NAME, [standardModule, orderController, orderListComponent, bidsViewComponent, orderActionsController, orderInformationCtrl, objectiveViewComponent])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('orders.order', 'Order', 'LineItem', 'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile, LocalUserPermissions) {
          return {
            func: function(ctrl, afterFill) {
              orderListCommons(ctrl, afterFill, LocalNetworkProfile, LocalUserPermissions);
            }
          };
        },
        sectionParam: function() {
          return 'orders';
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: singlePanelBottomTemplate
          },
          'topPanel@app.orders.order': {
            template: editTemplate,
            controller: 'OrderCtrl',
            controllerAs: 'resource'
          },
          'bottomPanel@app.orders.order': {
            template: informationTemplate,
            controller: 'OrderInformationCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'OrderActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Order', [{type: 'Advertiser', func: 'canBeShownAdvertiser'}]));
  });

export default MODULE_NAME;
