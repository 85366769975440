/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './order-copy-modal.html';

import {Uuid} from "../../components/util/uuid";

import standardActionsModule from '../common/standard-actions-module';

const MODULE_NAME = "order-actions-controller";

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('OrderActionsCtrl', function($q, toastr, $log, $stateParams, adnModalLoading, Order, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;
    ctrl.confirmation = {
      warningMessage: "The {{action}} of the Order will also stop any of the Order's Line Items from serving ads.",
      object: 'Order'
    };

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        windowClass: '',
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.order = data;
              modalCtrl.order.name = data.name + " (copy)";
            }
          });

          modalCtrl.destination = true;
          modalCtrl.copy = {
            targeting: true,
            creatives: true,
            lineItems: true,
            userState: ''
          };

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyOrder = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();
            const newId = Uuid.generate();
            $q.all(Order.copyOrder(modalCtrl.order, newId, modalCtrl.copy.userState, modalCtrl.copy.lineItems, modalCtrl.copy.targeting, modalCtrl.copy.creatives)).then(function() {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, orderId: newId});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        if (result.destination) {
          $state.go('app.orders.order', {id: result.orderId}, {inherit: false});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This order could not be copied.", "Creative Copy Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this order was successfully created.", "Order Copied");
        }
      }, function() {
        // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
      });
    };
  });

export default MODULE_NAME;