/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import progressBarComponent from './progress-bar-percentage-component';

import localNetworkProfile from '../../../components/session/local-network-profile';

import template from './order-list-component.html';
import {getListChildren} from "./resource-list-controller";
import {OBJECT_TYPE} from "../constants/object-type";
import {lineItemBulkAction} from "../../line-item/line-item-commons";

const MODULE_NAME = "order-list-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile, progressBarComponent])

  .component('adnOrderList', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      list: '<',
      readOnly: '<readonly'
    },
    controller: function($templateCache, adnListHelper, LineItem, $uibModal, $filter, $translate, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.list.canExpand = true;

        if (!ctrl.list.getChildren) {
          getListChildren(ctrl.list, adnListHelper, OBJECT_TYPE.Order, LineItem);
        }

        if (!ctrl.list.bulkAction) {
          ctrl.list.bulkAction = lineItemBulkAction(ctrl.list, {$uibModal: $uibModal, $filter: $filter, $translate: $translate}, {}, {LocalNetworkProfile: LocalNetworkProfile, LocalUserPermissions: LocalUserPermissions, adnTimeShifter: adnTimeShifter});
        }
      };
    }
  });

export default MODULE_NAME;